<template>
       <div class="row-plans-tabs plain-element text-center">
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div class="btn-plans-tab" @click="activateTab('plan-details')">
            <div v-if="tab === 'plan-details'" class="tab-plans-active">Plan Details</div>
            <div v-else>Plan Details</div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div  class="btn-plans-tab" @click="activateTab('opportunities')">
            <div v-if="tab === 'opportunities'" class="tab-plans-active">Opportunities</div>
            <div v-else>Opportunities</div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div  class="btn-plans-tab" @click="activateTab('climate-graphs')">
            <div v-if="tab === 'climate-graphs'" class="tab-plans-active">Climate Graphs</div>
            <div v-else>Climate Graphs</div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div  class="btn-plans-tab" @click="activateTab('financial-graphs')">
            <div v-if="tab === 'financial-graphs'" class="tab-plans-active">Financial Graphs</div>
            <div v-else>Financial Graphs</div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div class="btn-plans-tab" v-if="parseInt(opportunity_plan_relations_length) > 0">
            <div>MACC Graphs</div>
          </div>
        </div>
        <div class=" col col-4 col-sm-4 col-md-3 col-lg-2 plain-element text-start">
          <div class="btn-plans-tab" v-if="parseInt(custom_graphs_length) > 0" @click="activateTab('custom-graphs')">
            <div v-if="tab === 'custom-graphs'" class="tab-plans-active">Custom Graphs</div>
            <div v-else>Custom Graphs</div>
          </div>
        </div>
      </div>
</template>

<script>



export default {
  name: "SitePlanTabsComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
    opportunity_plan_relations_length: {
      type: Number,
      required: true,
    },
    custom_graphs_length: {
      type: Number,
      required: true,
    }
  },
  components: {

  },
  data() {
    return {
    }
  },
  methods: {
    activateTab(activatedTab) {
        this.$router.push({ name: 'site-plan-view', params: { id: this.id, tab: activatedTab } });
    },
    goToMaccGraph() {
      if (event.ctrlKey === true) {
        const customPath = '/api/site-macc-graph/' + this.id;
        const routeData = this.$router.resolve({ path: customPath });
        window.open(routeData.href, '_blank');
      } else {
        const customPath = '/api/site-macc-graph/' + this.id;
        const routeData = this.$router.resolve({ path: customPath });
        window.location.href = routeData.href; // Navigate to the custom path
      }
    },
  },

  computed: {


  },
  created() {


  },
}
</script>
