<template>
<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getSitePlanData()" class="dashboard-site">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <a :to="{name: 'home'}" class="horizontal-link">Home</a>
        </li>
        <li>
          <a :to="{name: 'site-plan-search'}" class="horizontal-link">Site Plan Search
          </a>
        </li>
        <li>
          <a :to="{name: 'site-plan-create'}" class="horizontal-link">Create Site Plan</a>
        </li>
      </ul>
    </div>
    <div class="card card-details">
      <div class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <div class="row row-functions text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
              <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
                   class="img responsive img-icon">
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 plain-element text-start">
              <h3 v-if="getSitePlanData().name.length < 46"  >{{ truncatechars(getSitePlanData().name, 50) }}</h3>
              <h4 v-else  style="line-height: 32px;">{{ truncatechars(getSitePlanData().name, 60) }}</h4>
            </div>
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-7 plain-element col-buttons">
              <a class="btn btn-report">
                 Publish
              </a>
              <a class="btn btn-report">
                 Copy Plan
              </a>
              <a class="btn btn-report">
                  Excel
              </a>
              <a  class="btn btn-report">
                  PDF
              </a>
              <a  class="btn btn-report">
                Update
              </a>
              <a  class="btn btn-report">
                Manage Opps.
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row plain-element">
        <SitePlanTabsComponent
          :id = getSitePlanData().id.toString()
          :tab = tab
          :opportunity_plan_relations_length = getSitePlanData().opportunity_plan_relations.length
          :custom_graphs_length = getSitePlanData().custom_graphs.graph_count
        />
      </div>
      <p v-if="tab === 'plan-details'" class="w-100"></p>
      <div v-if="tab === 'plan-details'" class="row plain-element">
        <SitePlanDetailsComponent
          :sitePlanData = getSitePlanData()
          siteName = 'DEMO'
        />
      </div>
      <div  v-if="tab === 'opportunities' " class="row plain-element">
        <SitePlanOpportunitiesComponent
          :id = getSitePlanData().id.toString()
          :seus = getSitePlanData().additional_details.seus
          :opportunities = getSitePlanData().opportunity_plan_relations
          :publish = getSitePlanData().publish
          :currency_details = getSitePlanData().currency_details
          siteName = 'DEMO'
          :carbonPerEuroDict = getCarbonPerEuroDict()
        />
      </div>
      <div v-if="tab === 'climate-graphs'" class="row plain-element">
        <SitePlanClimateGraphsComponent
          :id = getSitePlanData().id
          :opportunity_plan_relations_length = getSitePlanData().opportunity_plan_relations.length
          :carbonClimateActionPhaseWaterfallChartData = getCarbonClimateActionPhaseWaterfallChartData()
          :carbonWaterfallChartData = getCarbonWaterfallChartData()
          :carbonChartData = getCarbonChartData()
          :sitePlanCarbonTracker = getSitePlanCarbonTracker()
          :publish = getSitePlanData().publish
        />

      </div>
      <div v-if="tab === 'financial-graphs'" class="row plain-element">
        <SitePlanFinancialGraphsComponent
          :opportunity_plan_relations_length = getSitePlanData().opportunity_plan_relations.length
          :investmentWaterfallChartData = getInvestmentWaterfallChartData()
          :costSavingsWaterfallChartData = getCostSavingsWaterfallChartData()
          :investmentChartData = getInvestmentChartData()
          :financialClimateActionPhaseWaterfallChartData = getFinancialClimateActionPhaseWaterfallChartData()
          :costSavingsChartData = getCostSavingsChartData()
          :carbonPerEuroChartData = getCarbonPerEuroChartData()
          :currency_details = getSitePlanData().currency_details
        />
      </div>
      <div v-if="tab === 'custom-graphs'" class="row plain-element">
        <p class="w-100"></p>
        <div v-if="getSitePlanData().custom_graphs.graph_count > 0" class="row plain-element">
            <div v-for="(graph, index) in getSitePlanData().custom_graphs.waterfall_graphs" class=" col col-12 col-sm-12 col-md-12 col-lg-6" :key="index">
                <div class="row plain-element text-start">
                    <div style="width: 98%;">
                    <div class="row plain-element text-start">
                        <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                            <h6>{{ graph.title }}({{ graph.unit }}):</h6>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-end">
                            <a :href="'/graph-manage/' + graph.id"
                               class="btn btn-reorder">Manage Graph</a>
                        </div>
                        </div>
                        <div class="plan-chart">
                            <custom-waterfall-graph
                                    :chart-data='{"labels": graph.labels, "datasets":[{"backgroundColor": graph.colors, "data":graph.y, "tooltips": graph.tooltips}] }'></custom-waterfall-graph>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="(graph, index) in getSitePlanData().custom_graphs.area_graphs" class=" col col-12 col-sm-12 col-md-12 col-lg-6" :key="'area-' + index">
                <div class="row plain-element text-start">
                    <div style="width: 98%;">
                    <div class="row plain-element text-start">
                        <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                            <h6>{{ graph.title }}({{ graph.unit }}):</h6>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-end">
                            <a :href="'/area-graph-manage/' + graph.id"
                               class="btn btn-reorder">Manage Graph</a>
                        </div>
                        </div>
                        <div class="plan-chart">
                            <custom-area-graph
                                    :chart-data='{"labels": graph.labels, "datasets":[{"data":graph.y, "tooltips": graph.tooltips, "fill": true, "backgroundColor": setOpacity(graph.area_color, 0.5), "y_max": graph.y_max}] }'></custom-area-graph>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>


import { mapGetters, mapActions } from "vuex";

import { truncatechars } from "@/common/general.js";
import SitePlanTabsComponent from "@/components/site_plans/SitePlanTabsComponent.vue";
import SitePlanDetailsComponent from "@/components/site_plans/SitePlanDetailsComponent.vue";
import SitePlanOpportunitiesComponent from "@/components/site_plans/SitePlanOpportunitiesComponent.vue";
import SitePlanClimateGraphsComponent from "@/components/site_plans/SitePlanClimateGraphsComponent.vue";
import SitePlanFinancialGraphsComponent from "@/components/site_plans/SitePlanFinancialGraphsComponent.vue";
import NoPermissionComponent from "@/components/NoPermissionComponent.vue";

import CustomWaterfallGraph from "@/common/graphs/CustomWaterfallGraph.js";
import CustomAreaGraph from "@/common/graphs/CustomAreaGraph.js";

export default {
  name: "SitePlanView",
  props: {
     id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    }
  },
  data() {
    return {

    }
  },


  components: {
    SitePlanTabsComponent,
    SitePlanDetailsComponent,
    SitePlanOpportunitiesComponent,
    SitePlanClimateGraphsComponent,
    SitePlanFinancialGraphsComponent,
    NoPermissionComponent,
    CustomWaterfallGraph,
    CustomAreaGraph,
  },
  mounted() {

  },
  methods: {
    ...mapGetters(["getSitePlanData", "getUsername", "isLoggedIn", "getCompanyString","getInvestmentWaterfallChartData",
                    "getInvestmentChartData", "getCarbonChartData","getCarbonWaterfallChartData", "getSitePlanCarbonTracker",
                    "getCostSavingsWaterfallChartData", "getCostSavingsChartData", "getCarbonClimateActionPhaseWaterfallChartData",
                    "getFinancialClimateActionPhaseWaterfallChartData", "getCarbonPerEuroChartData", "getCarbonPerEuroDict"]),
    ...mapActions(["fetchSitePlanData", "performSetFormError", "fetchSitePlanCarbonTracker"]),

    truncatechars: truncatechars,
    getSitePlanDetails(id) {
      this.fetchSitePlanData({"id": id })
    },
    setOpacity(color, opacity) {
      // Convert hex color to rgba with the specified opacity
      const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
      };

      return `rgba(${hexToRgb(color)}, ${opacity})`;
    },

  },

  created() {
    this.getSitePlanDetails(this.id);
    this.performSetFormError(null);
    document.title = "LRP Management System";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenId = to.params.id;
    this.getSitePlanDetails(this.chosenId);
    next();
  }

}
</script>