<template>
<div class="row plain-element">
  <p class="w-100"></p>
  <div class="row plain-element">
    <div v-if="parseInt(opportunity_plan_relations_length) > 0" class=" col col-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row plain-element text-start">
        <h6>CO2 Reduction per Climate Action Phase(Tonnes):</h6>
      </div>
      <div class="plan-chart">
        <carbon-climate-action-phase-waterfall-graph :chart-data="carbonClimateActionPhaseWaterfallChartData"></carbon-climate-action-phase-waterfall-graph>
      </div>
    </div>

    <div  v-if="parseInt(opportunity_plan_relations_length) > 0" class=" col col-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row plain-element text-start">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-4">
          <h6>Annual CO2 Emission(Tonnes):</h6>
        </div>
        <div v-if="isSumGreaterThanZero(sitePlanCarbonTracker)" class="col col-12 col-sm-2 col-md-4 col-lg-4 text-center" style="padding-top: 6px;">
          <div class="legend-square" style="background-color: #ff7f0f;"></div>
          <div style="display: inline-block; font-size: 14px; margin-left: 6px;"> Actual CO2 Tonnes </div>
        </div>
        <div v-else class="col col-12 col-sm-2 col-md-4 col-lg-4 text-center">
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 text-end">
          <a v-if="!publish" class="btn btn-reorder"> CO2 Tracker</a>
        </div>
      </div>
      <div v-if="carbonWaterfallChartData" class="plan-chart">
        <carbon-waterfall-graph :chart-data="carbonWaterfallChartData"></carbon-waterfall-graph>
      </div>
    </div>
  </div>
  <div v-if="parseInt(opportunity_plan_relations_length) > 0" class="row plain-element">
    <div class=" col col-12 col-sm-2 col-md-1 col-lg-1 text-start"></div>
    <div class=" col col-12 col-sm-2 col-md-2 col-lg-2 text-start" v-for="key,value in climateActionLegend" :key="value">
      <div class="legend-square" :style="'background-color:' + key"> </div>
      <div style="display: inline-block; font-size: 14px; margin-left: 6px;">
        {{ value }}
      </div>
    </div>
  </div>
  <div v-if="parseInt(opportunity_plan_relations_length) > 0" class="row plain-element">
    <div class=" col col-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row plain-element text-start">
        <h6>CO2 Reduction By Opportunity(Tonnes):</h6>
      </div>
      <div class="plan-chart">
        <carbon-graph :chart-data="carbonChartData"></carbon-graph>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import CarbonGraph from "@/common/graphs/CarbonGraph.js";

import CarbonWaterfallGraph from "@/common/graphs/CarbonWaterfallGraph.js";

import CarbonClimateActionPhaseWaterfallGraph from "@/common/graphs/CarbonClimateActionPhaseWaterfallGraph.js";

export default {
  name: "SitePlanClimateGraphsComponent",
  props: {
    opportunity_plan_relations_length: {
      type: Number,
      required: true,
    },
    carbonClimateActionPhaseWaterfallChartData: {
      type: Object,
      required: true,
    },
    carbonWaterfallChartData: {
      type: Object,
      required: true,
    },
    carbonChartData: {
      type: Object,
      required: true,
    },
    sitePlanCarbonTracker: {
      type: Object,
      required: true,
    },
    publish: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    CarbonGraph,
    CarbonWaterfallGraph,
    CarbonClimateActionPhaseWaterfallGraph,
  },
  data() {
    return {
      climateActionLegend: {
        "1 - Low/No Cost": "#ff9d9a",
        "2 - Energy Efficiency": "#86bcb6",
        "3 - Renewable Electricity": "#f1ce63",
        "4 - Electrification of Heat": "#a0cbe8",
        "5 - Renewable Fuels": "#d4a6c8",
      },
    }
  },
  methods: {
    isSumGreaterThanZero(obj) {
      let sum = 0;
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          sum += obj[key];
        }
      }
      return sum > 0;
    },
  },

  computed: {


  },
  created() {


  },
}
</script>
